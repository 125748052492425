<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top: 20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="城中村名称" prop="villageName" style="margin-bottom: 0px">
                <el-input v-model="form.villageName" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="性质">
                <el-select v-model="form.belong" placeholder="请选择" >
                  <el-option
                    v-for="(item, idx) in $enums_hw.belong.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="清扫保洁面积(m²)" prop="cleanArea">
                <el-input v-model="form.cleanArea" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="人口数量" prop="population">
                <el-input v-model="form.population" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="非居住建筑数量(栋)" prop="unLiveNum">
                <el-input v-model="form.unLiveNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="村内总户数(户)" prop="familyNum">
                <el-input v-model="form.familyNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="居住建筑数量(栋)" prop="liveNum">
                <el-input v-model="form.liveNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="居住建筑平均数(层)" prop="liveAverageNum">
                <el-input v-model="form.liveAverageNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="地址">
                <el-input v-model="form.villageAddress" placeholder="请输入" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item label="范围">
                <el-input v-model="form.villageScope" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map-polygon v-model="mapData"></ayl-map-polygon>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import edit from "@/mixins/edit";
export default {
  mixins: [edit],

  data() {
    return {
      nav: [{ name: "编辑城中村" }],
      debug: 0,
      //地图数据
      mapData: {
        polygonScope: null, //（1个）多边形具体的点，形如           [[118,36],[117,32],[116,113]]
        scope: null //（1个）围栏范围，经纬度点阵数组，形如  [[118,36],[117,32],[116,113]]
      },
      form: {
        belong: null, //性质 0-村、1-居委会
        bidId: null,
        bidMaintainUnit: null, //主管单位
        cleanArea: null, //清扫保洁面积
        familyNum: null, //村内总户数
        isDrawn: 0,
        liveAverageNum: null, //居住建筑平均数
        liveNum: null, //居住建筑数量
        population: null, //人口数量
        unLiveNum: null, //非居住建筑数量
        villageAddress: null, //地址
        villageName: null,
        villageScope: null, //范围
        villageCoordinateScope: null //坐标范围
      },
      rules: {
        bidId: [
          {
            required: true,
            message: "请选择标段",
            trigger: "change"
          }
        ],
        villageName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位城中村名称",
            trigger: "blur"
          }
        ],
        cleanArea:{ trigger: "blur", validator: this.$validator.isNumber },
        familyNum:{ trigger: "blur", validator: this.$validator.positiveInteger },
        liveAverageNum:{ trigger: "blur", validator: this.$validator.positiveInteger },
        liveNum:{ trigger: "blur", validator: this.$validator.positiveInteger },
        population:{ trigger: "blur", validator: this.$validator.positiveInteger },
        unLiveNum:{ trigger: "blur", validator: this.$validator.positiveInteger },
      }
    };
  },
  methods: {
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      await self.$api_hw.urbanVillagesManagement_update({
        sanitationVillagePlotVO: _fixData(self.form)
      });
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "villageCoordinateScope":
              if (self.mapData.polygonScope) {
                d[key] = JSON.stringify(self.mapData.polygonScope);
                d.isDrawn = 1;
              }else{
                d[key] ="";
                d.isDrawn = 0;
              }
              break;
          }
        }
        self.log(d);
        return d;
      }
    },
    /**
     * @summary【钩子函数，函数名固定】
     * @description 获取编辑的数据。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async getInfo() {
      await _getData(this, this.$route.query.id);
    }
  }
};

async function _getData(self, id) {
  //详情数据
  let formData = await self.$api_hw.urbanVillagesManagement_getById({
    id: id
  });

  self.form = _fixData(formData);
  // this.form.ecoImageUrls = formData.ecoImageUrls;

  /**
   * 把服务器端的Data 修改一下以方便编辑
   */
  function _fixData(d) {
    //先检查key是否存在，不存在赋值null
    ["villageCoordinateScope", "bidScope"].forEach(key => {
      if (!d.hasOwnProperty(key)) {
        d[key] = null;
      }
    });

    for (let key in d) {
      switch (key) {
        case "villageCoordinateScope":
          try {
            self.mapData.polygonScope = JSON.parse(d[key]);
          } catch (e) {
            self.mapData.polygonScope = null;
          }
          break;
        case "bidScope":
          self.mapData.move2center = true;
          try {
            self.mapData.scope = JSON.parse(d[key]);
          } catch (e) {
            self.mapData.scope = null;
          }
          break;
      }
    }
    self.log(d);
    return d;
  }
}
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block
  .tips
    width: 407px
    height: 22px
    margin-left: 170px
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(255,140,26,1)
    line-height: 32px
  .map-popover
    position: absolute
    z-index: 2
    top: 12px
    right: 12px
    width: 180px
    height: 32px
    background: rgba(255,255,255,1)
    box-shadow: 0px 2px 8px 0px rgba(31,46,41,0.3)
    border-radius: 2px

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

</style>
